





























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'
import { Watch } from 'vue-property-decorator'

@Component({})
export default class GroupInvoiceReminderCustomer extends Vue {
  private isLoading = false
  private data = []
  private attachment = 'pdf'
  private hideNotDue = false
  private customer: {
    payerId: number
    payerName: string
    payerCustomerNumber: string
    payerEmailAddress: string
    balance: string
  } = {
    payerId: null,
    payerName: '',
    payerCustomerNumber: '',
    payerEmailAddress: '',
    balance: '',
  }

  private created() {
    if (this.$route.query?.hideNotDue) {
      this.hideNotDue = true
    }
    this.load()
  }

  private get payerId() {
    return this.$route.params.id || ''
  }

  @Watch('hideNotDue')
  private onChangeHideNotDue() {
    this.load()
  }

  private load() {
    this.data = []
    if (!this.payerId) {
      return
    }
    this.isLoading = true
    this.$axios
      .get(
        '/v4/site/payment/invoice-reminders/group/transactions/' +
          this.payerId +
          '?hideNotDue=' +
          (this.hideNotDue ? '1' : ''),
      )
      .then((response) => {
        this.data = response.data.data.transactions
        this.customer = response.data.data.customer
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed loading customer transactions')
        this.isLoading = false
      })
  }

  private clickSendReminder() {
    if (!this.customer?.payerEmailAddress) {
      alert(this.$t('Must enter e-mail address'))
      return
    }
    if (
      !confirm(
        this.$t('c:invoice-reminder:A reminder with the transactions below will be sent to %s').replace(
          '%s',
          this.customer.payerEmailAddress,
        ),
      )
    ) {
      return
    }
    this.isLoading = true
    this.$axios
      .get(
        '/v4/site/payment/invoice-reminders/group/transactions/' +
          this.payerId +
          '/send?attachment=' +
          encodeURIComponent(this.attachment) +
          '&email=' +
          encodeURIComponent(this.customer.payerEmailAddress),
      )
      .then((_response) => {
        this.load()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed sending reminder')
        this.isLoading = false
      })
  }

  private clickExcel() {
    this.$axios
      .get('/v4/site/payment/invoice-reminders/group/transactions/' + this.payerId + '/excel')
      .then((response) => {
        const a = document.createElement('a')
        a.href = 'data:application/msexcel;base64,' + response.data.data.excelData
        a.download = 'invoice-reminder-' + this.customer.payerCustomerNumber + '.xls'
        a.click()
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed generating excel file')
        this.isLoading = false
      })
  }

  private clickPdf() {
    this.$axios
      .get('/v4/site/payment/invoice-reminders/group/transactions/' + this.payerId + '/pdf')
      .then((response) => {
        const a = document.createElement('a')
        a.href = 'data:application/pdf;base64,' + response.data.data.pdfData
        a.download = 'invoice-reminder-' + this.customer.payerCustomerNumber + '.pdf'
        a.click()
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed generating pdf file')
        this.isLoading = false
      })
  }

  private clickBack() {
    this.$router.back()
  }

  private get customerUrl() {
    if (!this.customer?.payerId) {
      return ''
    }
    return appendSitePrefix('/' + this.customer?.payerId + '/customer/show')
  }

  private getItemLink(item): string {
    const invoiceNumber = item.originInvoiceNumber
    const url =
      '/U/ledger/main?is_closed=&from=&to=2022-11-10&payer=&mode=easy&easy_types%5B%5D=invoice&invoice=' +
      invoiceNumber +
      '&group_by=payer&submitted=1'
    return appendSitePrefix(url)
  }

  private getReminderLink(item): string {
    const invoiceNumber = item.originInvoiceNumber
    if (item.reminderCount > 0 && invoiceNumber) {
      const url = '/U/order_payment_document_reminder/history?invoice_number=' + invoiceNumber
      return appendSitePrefix(url)
    } else {
      return ''
    }
  }
}
